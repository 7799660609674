<script setup>
import { onMounted, onBeforeUnmount, ref, onBeforeMount, inject } from 'vue'
import WOW from 'wow.js'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import ContactView from '@/views/contact/ContactView.vue'
import SmartBanner from '@/components/smartBanner/SmartBanner.vue'

const IS_MOBILE_APP = inject('IS_MOBILE_APP')
const restoStore = useRestoGLobalStore()
const dynamicContent = restoStore?.restoInfo?.dynamic_site_content
const address = `${restoStore?.restoInfo?.address}`
const name = `${restoStore?.restoInfo?.name}`
const query = `${name} ${address}`
const mapSrc = `https://maps.google.com/maps?q=` + encodeURI(query) + `&output=embed`

const date = new Date()
const currentYear = date.getFullYear()
const isSticky = ref(false)
const showMobileHeader = ref(false)
const bgColor1 = ref('')

const handleScroll = () => {
  isSticky.value = window.scrollY > 50
}
const handleShowHeader = () => {
  showMobileHeader.value = !showMobileHeader.value
}
onBeforeMount(() => {
  if (dynamicContent?.style == 1) {
    import('@/layouts/dynamicSite/template2/css/style1.css')
    bgColor1.value = '#cda45e'
  } else if (dynamicContent?.style == 2) {
    import('@/layouts/dynamicSite/template2/css/style2.css')
    bgColor1.value = '#d33a11'
  } else if (dynamicContent?.style == 3) {
    import('@/layouts/dynamicSite/template2/css/style3.css')
    bgColor1.value = '#40ba37'
  } else {
    import('@/layouts/dynamicSite/template2/css/style4.css')
    bgColor1.value = '#FFC000'
  }
})
onMounted(() => {
  window.addEventListener('scroll', handleScroll)
  new WOW().init()
  const favicon = document.querySelector('link[rel="icon"]')
  if (favicon) {
    favicon.href = `${dynamicContent?.info?.logo}`
  } else {
    const newFavicon = document.createElement('link')
    newFavicon.rel = 'icon'
    newFavicon.type = 'image/x-icon'
    newFavicon.href = `${dynamicContent?.info?.logo}`
    document.head.appendChild(newFavicon)
  }
})
onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>
<template>
  <div class="temp-w2">
    <!-- SmartBanner -->
    <SmartBanner v-if="!IS_MOBILE_APP"></SmartBanner>
    <!-- Header -->
    <header
      :class="{ 'sticky-header': isSticky }"
      class="text-white sticky top-0 z-50 py-4 px-3 transition ease-in-out delay-150"
    >
      <div class="container mx-auto flex items-center justify-between">
        <!-- Logo -->
        <div class="flex items-center">
          <img :src="dynamicContent?.info?.logo" alt="Logo" class="h-12 w-12 mr-2" />
        </div>

        <!-- Navigation Links -->
        <nav class="hidden md:flex items-center space-x-8">
          <a href="#home" class="text-lg hover:text-gray-300">Home</a>
          <a href="#aboutUs" class="text-lg hover:text-gray-300">About Us</a>
          <router-link
            class="text-lg hover:text-gray-300"
            :to="{ name: 'menu', params: { menu_slug: restoStore?.MENU_URL } }"
            >Online Order</router-link
          >
          <a href="#gallery" class="text-lg hover:text-gray-300">Gallery</a>
          <a href="#contact" class="text-lg hover:text-gray-300">Contact</a>
          <router-link
            v-if="dynamicContent?.info?.is_reservation_active"
            class="btn-custom"
            :to="{ name: 'reservation', params: { resto_id: restoStore?.RESTO_ID } }"
            >Book a Table</router-link
          >
        </nav>

        <!-- Mobile Menu Button -->
        <button class="md:hidden focus:outline-none" @click="handleShowHeader()">
          <svg
            class="h-6 w-6 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16m-7 6h7"
            ></path>
          </svg>
        </button>

        <!-- Mobile Menu -->
        <div
          :class="[showMobileHeader ? 'block' : 'hidden']"
          class="mobile-menu bg-gray-600 absolute left-0 right-0 top-20"
        >
          <a href="#home" class="block hover:bg-gray-700 py-2 px-4">Home</a>
          <a href="#aboutUs" class="block hover:bg-gray-700 py-2 px-4">About Us</a>
          <router-link
            class="block hover:bg-gray-700 py-2 px-4"
            :to="{ name: 'menu', params: { menu_slug: restoStore?.MENU_URL } }"
            >Online Order</router-link
          >
          <a href="#gallery" class="block hover:bg-gray-700 py-2 px-4">Gallery</a>
          <a href="#contact" class="block hover:bg-gray-700 py-2 px-4">Contact</a>
        </div>
      </div>
    </header>

    <!-- Offer text -->
    <div
      v-if="dynamicContent?.content?.rsc_resto_note_customer_message"
      class="container-fluid top-notice pt-1 px-3"
    >
      <div class="top-notice-bar">
        <div class="flex">
          <div class="flex-none w-1/6 md:w-1/12 lg:w-1/12 xl:w-1/12 text-white font-semibold">
            Offers!
          </div>
          <div class="flex-grow w-5/6 md:w-11/12 lg:w-11/12 xl:w-11/12 text-white">
            <marquee
              class="hover:cursor-pointer"
              onmouseover="this.stop();"
              onmouseout="this.start();"
            >
              {{ dynamicContent?.content?.rsc_resto_note_customer_message }}
            </marquee>
          </div>
        </div>
      </div>
    </div>

    <section
      id="home"
      class="flex items-center bg-banner"
      :style="{
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${dynamicContent?.content?.rsc_slider_background_image_1_link})`
      }"
    >
      <div
        class="container mx-auto text-center md:text-left animate__animated animate__zoom-in animate__delay-100"
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <div class="wow bounceInLeft general_left animated">
          <h1 class="text-4xl lg:text-5xl font-bold lg:mb-5 mb-3">
            Welcome to <span class="text-primary">{{ dynamicContent?.info?.name }}</span>
          </h1>
          <!-- <h2 class="text-lg lg:text-2xl mb-10">
              {{ dynamicContent?.info?.promo_offers?.promo_offer_text }}
            </h2> -->
          <div v-if="dynamicContent?.info?.active_website_offers">
            <h2
              v-for="(offer, index) in dynamicContent?.info?.active_website_offers"
              :key="index"
              class="text-lg lg:text-xl mb-2"
            >
              {{ offer }}
            </h2>
          </div>
          <div class="md:flex space-x-4 mt-6">
            <router-link
              class="btn-custom btn-filled"
              :to="{ name: 'menu', params: { menu_slug: restoStore?.MENU_URL } }"
              >Our Menu</router-link
            >
            <router-link
              v-if="dynamicContent?.info?.is_reservation_active"
              class="btn-custom btn-filled"
              :to="{ name: 'reservation', params: { resto_id: restoStore?.RESTO_ID } }"
              >Book a Table</router-link
            >
          </div>
        </div>
      </div>
    </section>

    <!-- About us Content -->
    <section
      v-if="dynamicContent?.content?.rsc_about_us_text_1"
      id="aboutUs"
      class="about-section md:py-20 py-16 px-3"
    >
      <div class="container mx-auto animate__animated animate__fade-up" data-aos="fade-up">
        <div class="md:grid md:grid-cols-2 gap-10">
          <div
            class="content flex justify-center flex-col mb-5 wow bounceInLeft general_left animated"
            data-wow-duration="2s"
          >
            <div class="section-title md:mb-10 mb-6">
              <h2 class="text-primary text-3xl lg:text-4xl font-semibold title">About us</h2>
            </div>
            <p class="italic" v-html="dynamicContent?.content?.rsc_about_us_text_1"></p>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_about_us_image_1_link"
            class="wow bounceInRight general_right animated"
            data-wow-duration="2s"
          >
            <div
              class="about-img"
              :style="{
                backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${dynamicContent?.content?.rsc_about_us_image_1_link})`
              }"
            >
              <!-- <img :src="dynamicContent?.content?.rsc_about_us_image_1_link" alt="" /> -->
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      v-if="dynamicContent?.content?.rsc_about_us_text_2"
      class="about-section-2 md:py-20 py-16 px-3"
    >
      <div
        class="container mx-auto animate__animated animate__fade-up w-4/6 text-center"
        data-wow-duration="2s"
        data-wow-iteration="100"
        data-aos="fade-up"
      >
        <div class="section-title">
          <h3 class="text-primary text-2xl lg:text-4xl font-semibold mb-4 italic title">
            About us
          </h3>
        </div>
        <p v-html="dynamicContent?.content?.rsc_about_us_text_2"></p>
      </div>
    </section>

    <!-- Gallery section start here -->
    <section
      v-if="dynamicContent?.content?.rsc_gallery_image_1_text"
      id="gallery"
      class="gallery-section bg-white md:py-20 py-16 px-3"
    >
      <div class="container mx-auto animate__animated animate__fade-up" data-aos="fade-up">
        <div class="section-title md:mb-10 mb-6">
          <h2 class="text-primary text-3xl lg:text-4xl font-semibold title">Our Gallery</h2>
        </div>
        <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 content">
          <div
            v-if="dynamicContent?.content?.rsc_gallery_image_1_text"
            class="col-span-1 md:col-span-1 lg:col-span-1 wow zoomIn general_grow animated"
          >
            <div class="gallery-item">
              <img
                :src="`${dynamicContent?.content?.rsc_gallery_image_1_link}`"
                alt="Gallery image 1"
              />
            </div>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_gallery_image_2_text"
            class="col-span-1 md:col-span-1 lg:col-span-1 wow zoomIn general_grow animated"
          >
            <div class="gallery-item">
              <img
                :src="`${dynamicContent?.content?.rsc_gallery_image_2_link}`"
                alt="Gallery image 2"
              />
            </div>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_gallery_image_3_text"
            class="col-span-1 md:col-span-1 lg:col-span-1 wow zoomIn general_grow animated"
          >
            <div class="gallery-item">
              <img
                :src="`${dynamicContent?.content?.rsc_gallery_image_3_link}`"
                alt="Gallery image 3"
              />
            </div>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_gallery_image_4_text"
            class="col-span-1 md:col-span-1 lg:col-span-1 wow zoomIn general_grow animated"
          >
            <div class="gallery-item">
              <img
                :src="`${dynamicContent?.content?.rsc_gallery_image_4_link}`"
                alt="Gallery image 4"
              />
            </div>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_gallery_image_5_text"
            class="col-span-1 md:col-span-1 lg:col-span-1 wow zoomIn general_grow animated"
          >
            <div class="gallery-item">
              <img
                :src="`${dynamicContent?.content?.rsc_gallery_image_5_link}`"
                alt="Gallery image 5"
              />
            </div>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_gallery_image_6_text"
            class="col-span-1 md:col-span-1 lg:col-span-1 wow zoomIn general_grow animated"
          >
            <div class="gallery-item">
              <img
                :src="`${dynamicContent?.content?.rsc_gallery_image_6_link}`"
                alt="Gallery image 6"
              />
            </div>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_gallery_image_7_text"
            class="col-span-1 md:col-span-1 lg:col-span-1 wow zoomIn general_grow animated"
          >
            <div class="gallery-item">
              <img
                :src="`${dynamicContent?.content?.rsc_gallery_image_7_link}`"
                alt="Gallery image 7"
              />
            </div>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_gallery_image_8_text"
            class="col-span-1 md:col-span-1 lg:col-span-1 wow zoomIn general_grow animated"
          >
            <div class="gallery-item">
              <img
                :src="`${dynamicContent?.content?.rsc_gallery_image_8_link}`"
                alt="Gallery image 8"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Our Videos section start here -->
    <section
      v-if="dynamicContent?.content?.rsc_vgallery_link_1"
      id="ourVideos"
      class="gallery-section bg-white md:py-20 py-16 px-3"
    >
      <div class="container mx-auto animate__animated animate__fade-up" data-aos="fade-up">
        <div class="section-title mb-12">
          <p class="">Videos</p>
          <h2 class="text-primary text-2xl lg:text-4xl font-semibold mb-4">Our Videos</h2>
        </div>

        <div class="flex justify-center flex-wrap content">
          <div
            v-if="dynamicContent?.content?.rsc_vgallery_link_1"
            class="relative overflow-hidden sm:w-2/4 w-full px-2 mb-4 wow zoomIn general_grow animated"
          >
            <iframe width="100%" height="315" :src="dynamicContent?.content?.rsc_vgallery_link_1">
            </iframe>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_vgallery_link_2"
            class="relative overflow-hidden sm:w-2/4 w-full px-2 mb-4 wow zoomIn general_grow animated"
          >
            <iframe width="100%" height="315" :src="dynamicContent?.content?.rsc_vgallery_link_2">
            </iframe>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_vgallery_link_3"
            class="relative overflow-hidden sm:w-2/4 w-full px-2 mb-4 wow zoomIn general_grow animated"
          >
            <iframe width="100%" height="315" :src="dynamicContent?.content?.rsc_vgallery_link_3">
            </iframe>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_vgallery_link_4"
            class="relative overflow-hidden sm:w-2/4 w-full px-2 mb-4 wow zoomIn general_grow animated"
          >
            <iframe width="100%" height="315" :src="dynamicContent?.content?.rsc_vgallery_link_4">
            </iframe>
          </div>
        </div>
      </div>
    </section>

    <!-- Menu section start here -->
    <!-- <section id="menu" class="menu-section py-20">
      <div class="container mx-auto animate__animated animate__fade-up" data-aos="fade-up">
        <div class="section-title mb-8">
          <p>Menu</p>
          <h2 class="text-primary text-2xl lg:text-4xl font-semibold mb-4">Check Our Menu</h2>
        </div>
        <div
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 menu-container animate__animated animate__fade-up animate__delay-200"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div class="col-span-1 lg:col-span-1 menu-item filter-starters relative py-4">
            <div class="menu-content flex justify-between align-center w-full">
              <span class="text-lg font-semibold">Lobster Bisque</span>
              <span
                class="separator border border-dotted border-gray-400 mx-3 mt-3 mb-auto flex-grow"
              ></span>
              <span class="block text-primary">£5.95</span>
            </div>
            <div class="menu-ingredients text-gray-300 italic">
              Lorem, deren, trataro, filede, nerada
            </div>
          </div>
          <div class="col-span-1 lg:col-span-1 menu-item filter-starters relative py-4">
            <div class="menu-content flex justify-between align-center w-full">
              <span class="text-lg font-semibold">Lobster Bisque</span>
              <span
                class="separator border border-dotted border-gray-400 mx-3 mt-3 mb-auto flex-grow"
              ></span>
              <span class="block text-primary">£5.95</span>
            </div>
            <div class="menu-ingredients text-gray-300 italic">
              Lorem, deren, trataro, filede, nerada
            </div>
          </div>
          <div class="col-span-1 lg:col-span-1 menu-item filter-starters relative py-4">
            <div class="menu-content flex justify-between align-center w-full">
              <span class="text-lg font-semibold">Lobster Bisque</span>
              <span
                class="separator border border-dotted border-gray-400 mx-3 mt-3 mb-auto flex-grow"
              ></span>
              <span class="block text-primary">£5.95</span>
            </div>
            <div class="menu-ingredients text-gray-300 italic">
              Lorem, deren, trataro, filede, nerada
            </div>
          </div>
        </div>
      </div>
    </section> -->

    <!-- Contact us section start here -->
    <section id="contact" class="bg-gray-100 container-fluid mx-auto md:py-20 py-16 px-3">
      <div class="container mx-auto">
        <div class="section-title mb-12">
          <h2 class="text-primary text-2xl lg:text-4xl font-semibold mb-8 text-center">
            Contact Us
          </h2>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-2/3 md:p-4 wow zoomIn general_grow animated">
            <ContactView :bgcolor="bgColor1"></ContactView>
          </div>

          <div class="lg:block lg:w-1/3 w-full md:p-4 wow zoomIn general_grow animated">
            <iframe
              width="100%"
              height="450"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              :src="mapSrc"
            >
            </iframe>
          </div>
        </div>
      </div>
    </section>

    <section
      v-if="dynamicContent?.content?.rsc_food_hygiene_rating > 3"
      id="allergyInfo"
      class="container-fluid rating_section md:py-12 py-8 px-3"
    >
      <div class="container mx-auto">
        <div class="text-center mx-auto wow zoomIn general_grow animated">
          <div>
            <img
              v-if="dynamicContent?.content?.rsc_food_hygiene_rating == 3"
              src="./../common/images/food-rating-3.svg"
              class="mb-4 inline-block md:w-1/3 w-80"
            />
            <img
              v-else-if="dynamicContent?.content?.rsc_food_hygiene_rating == 4"
              src="./../common/images/food-rating-4.svg"
              class="mb-4 inline-block md:w-1/3 w-80"
            />
            <img
              v-else
              src="./../common/images/food-rating-5.svg"
              class="mb-4 inline-block md:w-1/3 w-80"
            />
          </div>
          <div v-if="dynamicContent?.content?.rsc_allergy_text_1" class="">
            <p class="font-semibold md:text-xl text-lg">
              {{ dynamicContent?.content?.rsc_allergy_text_1 }}
            </p>
            <p class="font-semibold md:text-xl text-lg">
              {{ dynamicContent?.content?.rsc_allergy_text_2 }}
            </p>
            <a
              class="font-semibold md:text-xl text-lg btn-custom btn-filled mt-5"
              href="https://ratings.food.gov.uk/"
              target="_blank"
              >For more details</a
            >
          </div>
        </div>
      </div>
    </section>

    <!-- Footer section start here -->
    <footer id="footer" class="">
      <div class="text-white md:py-20 py-12 px-3">
        <div class="container mx-auto">
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div
              class="col-span-1 md:col-span-2 lg:col-span-1 wow bounceInLeft general_left animated"
            >
              <div class="mb-6">
                <h4 class="text-primary text-xl lg:text-2xl font-semibold mb-5">
                  {{ dynamicContent?.info?.name }}
                </h4>
                <div class="flex mb-2">
                  <i class="fa fa-map-marker mr-2 mt-1"></i>
                  <p class="">
                    {{ dynamicContent?.info?.address }}
                  </p>
                </div>

                <div class="flex items-center mb-2">
                  <i class="fa fa-phone mr-2"></i>
                  <a :href="`tel:${dynamicContent?.info?.phone_number}`">{{
                    dynamicContent?.info?.phone_number
                  }}</a>
                </div>
                <!-- <div class="flex items-center mb-2">
                  <i class="fa fa-envelope mr-2"></i>
                  <a href="mailto:bookings@gmail.com">bookings@gmail.com</a>
                </div> -->

                <div class="social-links mt-5">
                  <a
                    v-if="dynamicContent.content?.rsc_social_media_link_facebook"
                    :href="dynamicContent.content?.rsc_social_media_link_facebook"
                    target="_blank"
                    class="text-blue-400 hover:text-blue-300"
                    ><i class="fa fa-facebook" aria-hidden="true"></i
                  ></a>
                  <a
                    v-if="dynamicContent.content?.rsc_social_media_link_instagram"
                    :href="dynamicContent.content?.rsc_social_media_link_instagram"
                    target="_blank"
                    class="text-blue-500 hover:text-blue-400"
                    ><i class="fa fa-instagram" aria-hidden="true"></i
                  ></a>
                  <a
                    v-if="dynamicContent.content?.rsc_social_media_link_twitter"
                    :href="dynamicContent.content?.rsc_social_media_link_twitter"
                    target="_blank"
                    class="text-red-500 hover:text-red-400"
                    ><i class="fa fa-twitter text-xl" aria-hidden="true"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-span-1 md:col-span-1 lg:col-span-1 wow zoomIn general_grow animated">
              <h4 class="text-primary text-xl lg:text-2xl font-semibold mb-5">Useful Links</h4>
              <ul>
                <li class="mb-2">
                  <i class="fa fa-chevron-right text-primary"></i>
                  <a href="#" class="text-white">Home</a>
                </li>
                <li class="mb-2">
                  <i class="fa fa-chevron-right text-primary"></i>
                  <a href="#aboutUs" class="text-white">About us</a>
                </li>
                <li class="mb-2">
                  <i class="fa fa-chevron-right text-primary"></i>
                  <a href="#gallery" class="text-white">Gallery</a>
                </li>
                <li class="mb-2">
                  <i class="fa fa-chevron-right text-primary"></i>
                  <a href="#contact" class="text-white">Contact us</a>
                </li>
              </ul>
            </div>

            <div
              class="col-span-1 md:col-span-2 lg:col-span-1 wow bounceInRight general_right animated"
            >
              <div class="flex items-center mb-4 text-xl">
                <i class="fa fa-clock-o mr-2 text-primary"></i>
                <h4 class="text-primary text-xl lg:text-2xl font-semibold">Opening Hours</h4>
              </div>
              <ul
                v-for="openTime in dynamicContent?.info?.opening_times"
                :key="openTime?.day_number"
                class="list-disc ml-4"
              >
                <li class="font-bold">
                  {{ openTime.day_title }}:
                  <span
                    class="font-normal italic"
                    v-for="(time, index) in openTime.times"
                    :key="index"
                    >{{ time }}</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-gray-800 text-white py-4">
        <div class="container mx-auto">
          <div class="flex justify-between">
            <div class="text-sm">&copy; {{ currentYear }} - All Rights Reserved</div>
          </div>
        </div>
      </div>
    </footer>

    <a
      id="back-to-top"
      href="#"
      class="fixed bottom-4 right-4 rounded-full p-2 bg-white shadow-md hover:bg-gray-100"
    >
      <i class="fa fa-chevron-up text-gray-700" aria-hidden="true"></i>
    </a>
  </div>
</template>
<style scoped>
@import 'https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;600&display=swap';
@import 'https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css';
@import '@/layouts/dynamicSite/template2/css/responsive.css';
@import '@/layouts/dynamicSite/common/css/animate.css';
</style>
