<script setup>
import { onMounted, inject } from 'vue'
import { useAuthGlobalStore } from '@/stores/authGlobalStore'
const authStore = useAuthGlobalStore()
import { useRouter } from 'vue-router'
import { useMobilePushNotification } from '@/composables/MobileNotification'
const { handleRemoveDeviceToken } = useMobilePushNotification()
const router = useRouter()
const IS_MOBILE_APP = inject('IS_MOBILE_APP')

const handleLogout = async () => {
  if (IS_MOBILE_APP) {
    handleRemoveDeviceToken()
  }
  await authStore.logout().then(() => {
    router.push({ name: 'restoHome' })
  })
}
onMounted(() => {
  handleLogout()
})
</script>
<template>&nbsp;</template>
