<script setup>
import AppLayout from '@/layouts/AppLayout.vue'
import { onMounted, inject } from 'vue'
import { useRouter } from 'vue-router'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useCartGLobalStore } from '@/stores/cartGlobalStore'
import CategoriesList from '@/components/menu/category/CategoriesList.vue'
import ProductList from '@/components/menu/product/ProductList.vue'
import ShoppingCart from '@/components/cart/ShoppingCart.vue'
import PromoOffer from '@/components/promoOffer/PromoOffer.vue'
import CountdownTimer from '@/components/countdownTimer/CountdownTimer.vue'
import RestoLiveStatus from '@/components/restoStatus/RestoLiveStatus.vue'
const IS_MOBILE_APP = inject('IS_MOBILE_APP')
const restoStore = useRestoGLobalStore()
const cartStore = useCartGLobalStore()

const handleRestoMenuUrl = async () => {
  const router = useRouter()
  const menuSlug = restoStore.restoInfo.menu_url
  const currentMenuSlugUrl = router.currentRoute.value.params.menu_slug
  if (menuSlug !== currentMenuSlugUrl) {
    return router.push({ name: 'menu', params: { menu_slug: menuSlug } })
  }
}

onMounted(() => {
  handleRestoMenuUrl()
  cartStore.fetchCartInfo()
  restoStore.checkAndRefetchRestoMenu()
})
</script>

<template>
  <AppLayout>
    <!-- <h1>This is menu page</h1> -->

    <div class="sm:px-4 lg:px-5 px-3 sm:py-3 lg:py-4 py-3">
      <div class="econtainer mx-auto">
        <div class="relative lg:gap-4 gap-3 md:grid md:grid-cols-12">
          <div class="md:col-span-2">
            <div
              id="categoryWrapper"
              class="md:sticky top-20 hidden md:block md:rounded-lg border border-gray-300 bg-white shadow-sm px-2 py-4"
            >
              <nav aria-label="Sidebar">
                <CategoriesList></CategoriesList>
              </nav>
            </div>
          </div>
          <div class="md:col-span-6">
            <RestoLiveStatus></RestoLiveStatus>
            <CountdownTimer :resto-status="cartStore?.RESTO_STATUS"></CountdownTimer>
            <PromoOffer></PromoOffer>
            <ProductList></ProductList>
          </div>
          <div class="md:col-span-4">
            <ShoppingCart></ShoppingCart>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:footer v-if="IS_MOBILE_APP">&nbsp;</template>
  </AppLayout>
</template>
<style>
#categoryWrapper {
  height: calc(100vh - 150px);
  overflow: auto;
}
</style>
