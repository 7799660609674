<script>
import AppLayout from '@/layouts/AppLayout.vue'
import SettingMenuLink from '@/views/account/setting/common/SettingMenuLink.vue'
import { ref, computed, onMounted, watch } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength, maxLength } from '@vuelidate/validators'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/20/solid'
import { updateCustomerInfoFn, getCustomerInfoFn } from '@/services/api/restApiCalls'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useRouter } from 'vue-router'
import LoadingSpinner from '@/components/common/LoadingSpinner/LoadingSpinner.vue'
import { notify } from 'notiwind'

export default {
  components: {
    AppLayout,
    CheckCircleIcon,
    XCircleIcon,
    SettingMenuLink,
    LoadingSpinner
  },
  setup() {
    // const authStore = useAuthGlobalStore()
    const restoStore = useRestoGLobalStore()
    const router = useRouter()

    const firstName = ref('')
    const lastName = ref('')
    const emailId = ref('')
    const phoneNumber = ref('')
    const addressLine1 = ref('')
    const addressLine2 = ref('')
    const postCode = ref('')
    const city = ref('')
    const submitted = ref(false)
    const emailDuplicate = ref(false)
    const emailInvalid = ref(false)
    const emailValid = ref(false)
    const isLoading = ref(false)
    const validationRules = computed(() => {
      let rules = {
        firstName: { required },
        lastName: { required },
        emailId: {
          required,
          email
        },
        phoneNumber: { required, minLength: minLength(10), maxLength: maxLength(15) },
        addressLine1: { required },
        postCode: { required }
      }
      return rules
    })
    const v$ = useVuelidate(validationRules, {
      firstName,
      lastName,
      emailId,
      phoneNumber,
      addressLine1,
      postCode
    })
    const handleUpdateCustomer = async () => {
      submitted.value = true
      v$.value.$validate()
      if (v$.value.$invalid) {
        return
      }
      if (isLoading.value) return
      isLoading.value = true
      let userUpdateFormData = new FormData()
      userUpdateFormData.append('first_name', firstName.value)
      userUpdateFormData.append('last_name', lastName.value)
      // userUpdateFormData.append('email', emailId.value)
      userUpdateFormData.append('phone_number', phoneNumber.value)
      userUpdateFormData.append('address_line1', addressLine1.value)
      userUpdateFormData.append('post_code', postCode.value)
      userUpdateFormData.append('address_line2', addressLine2.value)
      userUpdateFormData.append('city', city.value)
      const update_info = await updateCustomerInfoFn(userUpdateFormData)
      if (!update_info?.error_code) {
        emailValid.value = true
        notify(
          {
            group: 'successmessage',
            title: 'Success',
            text: `Settings updated successfully`
          },
          2000
        )
      }
      isLoading.value = false
    }
    const getCustomerInfo = async () => {
      const customer_info = await getCustomerInfoFn()
      firstName.value = customer_info?.first_name
      lastName.value = customer_info?.last_name
      emailId.value = customer_info?.email
      phoneNumber.value = customer_info?.phone_number
      addressLine1.value = customer_info?.address?.address_line1
      addressLine2.value = customer_info?.address?.address_line2
      postCode.value = customer_info?.address?.post_code
      city.value = customer_info?.address?.city
    }

    onMounted(() => {
      getCustomerInfo()
    })
    watch(
      [firstName, lastName, emailId, phoneNumber, addressLine1, addressLine2, postCode, city],
      () => {
        emailValid.value = false
      }
    )
    return {
      firstName,
      lastName,
      emailId,
      phoneNumber,
      addressLine1,
      addressLine2,
      postCode,
      city,
      submitted,
      emailDuplicate,
      emailInvalid,
      emailValid,
      v$,
      router,
      restoStore,
      handleUpdateCustomer,
      isLoading
    }
  }
}
</script>
<template>
  <AppLayout>
    <!-- <h1>Customer Setting VIEW</h1> -->
    <SettingMenuLink></SettingMenuLink>
    <div
      class="flex flex-col items-center justify-center sm:px-6 px-3 sm:py-8 py-4 mx-auto pt:mt-0"
    >
      <div class="w-full max-w-2xl p-6 sm:p-8 bg-white rounded-lg shadow">
        <div class="sm:mx-auto sm:w-full sm:max-w-md mb-6">
          <!-- <h1 class="mb-4 text-center text-xl font-semibold tracking-tight text-gray-900">Setting</h1> -->
          <h2 class="text-2xl font-bold text-gray-900 text-center">Setting</h2>
        </div>

        <div class="sm:mx-auto sm:w-full sm:max-w-4xl">
          <div class="">
            <form class="space-y-4" @submit.prevent="handleUpdateCustomer">
              <div v-if="emailDuplicate" class="rounded-md bg-red-50 p-4">
                <div class="flex justify-center items-center">
                  <div class="flex-shrink-0">
                    <XCircleIcon class="lg:h-8 lg:w-8 h-6 w-6 text-red-400" aria-hidden="true" />
                  </div>
                  <div class="ml-3">
                    <p class="text-md font-medium text-red-800">Email already register</p>
                  </div>
                </div>
              </div>
              <div v-if="emailInvalid" class="rounded-md bg-red-50 p-4">
                <div class="flex justify-center items-center">
                  <div class="flex-shrink-0">
                    <XCircleIcon class="lg:h-8 lg:w-8 h-6 w-6 text-red-400" aria-hidden="true" />
                  </div>
                  <div class="ml-3">
                    <p class="text-md font-medium text-red-800">Invalid Email</p>
                  </div>
                </div>
              </div>
              <div v-if="emailValid" class="rounded-md bg-green-50 p-4">
                <div class="flex justify-center items-center">
                  <div class="flex-shrink-0">
                    <CheckCircleIcon
                      class="lg:h-8 lg:w-8 h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div class="ml-3">
                    <p class="text-md font-medium text-green-800">Settings updated successfully</p>
                  </div>
                </div>
              </div>
              <div class="md:grid md:grid-cols-12 md:gap-x-6">
                <div class="md:col-span-6">
                  <label for="firstName" class="block text-sm font-medium text-gray-700"
                    >First Name</label
                  >
                  <div class="mt-1">
                    <input
                      id="firstName"
                      v-model="firstName"
                      name="firstName"
                      placeholder="First Name"
                      type="text"
                      autocomplete="firstName"
                      :class="{ 'border-red-600': submitted && v$.firstName.$error }"
                      class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                    />
                  </div>
                  <div
                    v-for="(error, index) of v$.firstName.$errors"
                    :key="index"
                    class="input-errors"
                  >
                    <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
                  </div>
                </div>

                <div class="md:col-span-6">
                  <label for="lastName" class="block text-sm font-medium text-gray-700"
                    >Last Name</label
                  >
                  <div class="mt-1">
                    <input
                      id="lastName"
                      v-model="lastName"
                      name="lastName"
                      placeholder="Last Name"
                      type="text"
                      autocomplete="lastName"
                      :class="{ 'border-red-600': submitted && v$.lastName.$error }"
                      class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                    />
                  </div>
                  <div
                    v-for="(error, index) of v$.lastName.$errors"
                    :key="index"
                    class="input-errors"
                  >
                    <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="md:grid md:grid-cols-12 md:gap-x-6">
                <div class="md:col-span-6">
                  <label for="email" class="block text-sm font-medium text-gray-700"
                    >Email address</label
                  >
                  <div class="mt-1">
                    <input
                      id="email"
                      v-model="emailId"
                      name="email"
                      placeholder="Email"
                      type="email"
                      autocomplete="email"
                      disabled
                      :class="{ 'border-red-600': submitted && v$.emailId.$error }"
                      class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                    />
                  </div>
                  <div
                    v-for="(error, index) of v$.emailId.$errors"
                    :key="index"
                    class="input-errors"
                  >
                    <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
                  </div>
                </div>

                <div class="md:col-span-6">
                  <label for="phoneNumber" class="block text-sm font-medium text-gray-700"
                    >Phone Number</label
                  >
                  <div class="mt-1">
                    <input
                      id="phoneNumber"
                      v-model="phoneNumber"
                      name="phoneNumber"
                      placeholder="Phone Number"
                      type="text"
                      autocomplete="phoneNumber"
                      :class="{ 'border-red-600': submitted && v$.phoneNumber.$error }"
                      class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                    />
                  </div>
                  <div
                    v-for="(error, index) of v$.phoneNumber.$errors"
                    :key="index"
                    class="input-errors"
                  >
                    <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="md:grid md:grid-cols-12 md:gap-x-6">
                <div class="md:col-span-6">
                  <label for="address" class="block text-sm font-medium text-gray-700"
                    >Address</label
                  >
                  <div class="mt-1">
                    <input
                      id="address"
                      v-model="addressLine1"
                      name="address_line1"
                      placeholder="Address Line 1"
                      type="text"
                      autocomplete="address"
                      :class="{ 'border-red-600': submitted && v$.addressLine1.$error }"
                      class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                    />
                  </div>
                  <div
                    v-for="(error, index) of v$.addressLine1.$errors"
                    :key="index"
                    class="input-errors"
                  >
                    <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
                  </div>
                </div>
                <div class="md:col-span-6">
                  <label for="address" class="invisible text-sm font-medium text-gray-700"
                    >Address</label
                  >
                  <input
                    id="address"
                    v-model="addressLine2"
                    name="address_line2"
                    placeholder="Address Line 2"
                    type="text"
                    autocomplete="address"
                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                  />
                </div>
              </div>
              <div class="md:grid md:grid-cols-12 md:gap-x-6">
                <div class="md:col-span-6">
                  <label for="address" class="block text-sm font-medium text-gray-700">City</label>
                  <div class="mt-1">
                    <input
                      id="city"
                      v-model="city"
                      name="city"
                      placeholder="City"
                      type="text"
                      autocomplete="city"
                      class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                    />
                  </div>
                </div>

                <div class="md:col-span-6">
                  <label for="postCode" class="block text-sm font-medium text-gray-700"
                    >Post Code</label
                  >
                  <div class="mt-1">
                    <input
                      id="postCode"
                      v-model="postCode"
                      name="postCode"
                      placeholder="Post Code"
                      type="text"
                      autocomplete="postCode"
                      :class="{ 'border-red-600': submitted && v$.postCode.$error }"
                      class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                    />
                  </div>
                  <div
                    v-for="(error, index) of v$.postCode.$errors"
                    :key="index"
                    class="input-errors"
                  >
                    <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
                  </div>
                </div>
              </div>
              <div class="flex justify-center text-center">
                <button
                  type="submit"
                  class="w-2/4 justify-center rounded-md border border-transparent bg-violet-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-violet-800 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2"
                >
                  Save
                </button>
                <button
                  type="button"
                  class="w-2/4 ml-2 rounded-md border border-transparent bg-gray-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-offset-gray-50"
                  @click="
                    router.push({
                      name: 'menu',
                      params: { menu_slug: restoStore.MENU_URL }
                    })
                  "
                >
                  Back
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
  <LoadingSpinner :isLoading="isLoading" />
</template>
