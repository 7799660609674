<script setup>
import { ref, onMounted, inject } from 'vue'
import AppLayout from '@/layouts/AppLayout.vue'
import { useCartGLobalStore } from '@/stores/cartGlobalStore'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import {
  getOrderOptionFn,
  getCartInfoFn,
  setOrderOptionsFn,
  getRestoPaymentSettingFn
} from '@/services/api/restApiCalls'
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions
} from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon, ShoppingBagIcon } from '@heroicons/vue/20/solid'
import { useRouter } from 'vue-router'
const router = useRouter()
const cartStore = useCartGLobalStore()
const restoStore = useRestoGLobalStore()
const selected = ref({ label: 'Select Order time', value: 30 })
const noteForResto = ref('')
const orderDatetimeOptions = ref([])
const showErrorMsg = ref({})
const defaultSelected = { label: 'ASAP', value: 30 }
const IS_MOBILE_APP = inject('IS_MOBILE_APP')

const getOrderOption = async () => {
  const order_option = await getOrderOptionFn(restoStore?.RESTO_ID, router)
  if (order_option.status == 400) {
    showErrorMsg.value = order_option
    return
  }
  orderDatetimeOptions.value = order_option?.order_datetime_options || []
  if (orderDatetimeOptions.value.length > 0) {
    selected.value = orderDatetimeOptions.value[0]
  } else {
    orderDatetimeOptions.value.push(defaultSelected)
    selected.value = defaultSelected
  }
  const cart_info = await getCartInfoFn(restoStore?.RESTO_ID)
  cartStore.CART_DATA = cart_info[restoStore?.RESTO_ID]
  noteForResto.value = cartStore.CART_DATA?.order_note || ''
}
const handleOrderOptions = async () => {
  let orderOptionFormData = new FormData()
  orderOptionFormData.append('resto_id', restoStore?.RESTO_ID)
  orderOptionFormData.append('requested_order_dt', selected.value.value)
  orderOptionFormData.append('order_note', noteForResto.value)
  if (IS_MOBILE_APP) {
    orderOptionFormData.append('is_mobile_app', 1)
  }
  const order_option = await setOrderOptionsFn(orderOptionFormData, router)
  if (order_option?.status == 200) {
    const payment_settings = await getRestoPaymentSettingFn(restoStore?.RESTO_ID)
    if (cartStore.CART_DATA?.order_payment_type == 'cash') {
      router.push({ name: 'cash_payment' })
    } else if (payment_settings?.gateway == 'stripe') {
      router.push({ name: 'stripe_card_payment' })
    } else {
      router.push({ name: 'menu', params: { menu_slug: restoStore.MENU_URL } })
    }
  }
}
onMounted(() => {
  getOrderOption()
})
</script>
<template>
  <AppLayout>
    <div
      class="flex flex-col items-center justify-center sm:px-6 px-3 sm:py-8 py-4 mx-auto pt:mt-0 dark:bg-gray-900"
    >
      <div class="w-full max-w-xl p-6 space-y-8 sm:p-8 bg-white rounded-lg shadow dark:bg-gray-800">
        <div>
          <div class="sm:flex-auto text-center">
            <h1
              class="md:text-2xl text-xl font-semibold text-gray-900 mb-3 flex items-center justify-center"
            >
              Confirm
              <span
                class="font-bold flex items-center capitalize mx-1 italic"
                :class="[
                  cartStore.CART_DATA?.order_type == 'delivery'
                    ? 'text-color-delivery'
                    : 'text-color-collection'
                ]"
              >
                <ShoppingBagIcon
                  v-if="cartStore.CART_DATA?.order_type == 'collection'"
                  class="h-5 w-5 mr-1 mb-1 text-color-collection"
                  aria-hidden="true"
                />
                <svg
                  v-else
                  class="h-5 w-5 text-color-delivery fill-current mr-1"
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    d="M20 11c-.18 0-.36.03-.53.05L17.41 9H20V6l-3.72 1.86L13.41 5H9v2h3.59l2 2H11l-4 2-2-2H0v2h4c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4l2 2h3l3.49-6.1 1.01 1.01c-.91.73-1.5 1.84-1.5 3.09 0 2.21 1.79 4 4 4s4-1.79 4-4-1.79-4-4-4zM4 17c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm16 0c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
                  ></path>
                </svg>
                {{ cartStore?.CART_DATA?.order_type }}</span
              >
              time
            </h1>
          </div>
          <div class="space-y-4">
            <div
              v-if="showErrorMsg.status == 400"
              class="bg-red-500 text-center text-white rounded-sm text-base font-medium px-2 py-1"
            >
              Sorry!! <span>{{ showErrorMsg.error_msg }}</span>
            </div>
            <Listbox v-model="selected" as="div">
              <ListboxLabel class="block text-sm font-medium text-gray-700"
                >Select Order time</ListboxLabel
              >
              <div class="relative mt-1">
                <ListboxButton
                  class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 text-sm"
                >
                  <span class="block truncate">{{ selected.label }}</span>
                  <span
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                  >
                    <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </ListboxButton>

                <transition
                  leave-active-class="transition ease-in duration-100"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <ListboxOptions
                    class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm"
                  >
                    <ListboxOption
                      v-for="dateTimeOption in orderDatetimeOptions"
                      :key="dateTimeOption.value"
                      v-slot="{ active, selected }"
                      as="template"
                      :value="dateTimeOption"
                      :disabled="showErrorMsg.status == 400"
                    >
                      <li
                        :class="[
                          active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-3 pr-9'
                        ]"
                      >
                        <span
                          :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']"
                          >{{ dateTimeOption.label }}</span
                        >

                        <span
                          v-if="selected"
                          :class="[
                            active ? 'text-white' : 'text-indigo-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                          ]"
                        >
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
            <div>
              <label for="comment" class="block text-sm font-medium text-gray-700"
                >Note For Restaurant</label
              >
              <div class="mt-1">
                <textarea
                  id="comment"
                  v-model="noteForResto"
                  rows="4"
                  name="comment"
                  placeholder="Add instruction about food or mention any allergy"
                  :disabled="showErrorMsg.status == 400"
                  class="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="space-y-4">
          <button
            type="submit"
            class="w-full justify-center rounded-md border border-transparent bg-violet-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-violet-800 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2 disabled:bg-gray-300 disabled:text-gray-400"
            @click="handleOrderOptions()"
            :disabled="showErrorMsg.status == 400"
          >
            Proceed to Checkout
          </button>
          <button
            type="submit"
            class="w-full rounded-md border border-transparent bg-gray-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-offset-gray-50"
            @click="
              $router.push({
                name: 'menu',
                params: { menu_slug: restoStore.MENU_URL }
              })
            "
          >
            Change Order
          </button>
        </div>
      </div>
    </div>
  </AppLayout>
</template>
