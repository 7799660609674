<script setup>
import { onMounted } from 'vue'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import '@/assets/css/smartbanner.css'
import playstoreIcon from '@/assets/images/playstore-icon.png'
import appstoreIcon from '@/assets/images/appstore-icon.png'
import SmartBanner from '@/assets/js/smartbanner.js'

const restoStore = useRestoGLobalStore()

onMounted(() => {
  let apple_app_id = restoStore.restoInfo?.mobileapp_ids.itunes_app_id || ''
  let google_app_id = restoStore.restoInfo?.mobileapp_ids.playstore_app_id || ''
  let enabledPlatforms = []
  apple_app_id ? enabledPlatforms.push('ios') : ''
  google_app_id ? enabledPlatforms.push('android') : ''

  try {
    const options = {
      title: restoStore.restoInfo?.name || '',
      author: 'OnlyGulp',
      buttonUrlGoogle: google_app_id
        ? 'https://play.google.com/store/apps/details?id=' + google_app_id
        : '',
      buttonUrlApple: apple_app_id ? 'https://apps.apple.com/app/' + apple_app_id : '',
      iconGoogle: playstoreIcon,
      iconApple: appstoreIcon,
      button: 'Install',
      price: 'Download for FREE',
      enabledPlatforms: enabledPlatforms.join(',')
    }
    // only initialize if there is at least one platform enabled
    if (enabledPlatforms.length > 0) {
      const smartbanner = new SmartBanner(options)
      if (smartbanner.apiEnabled) {
        window.smartbanner = smartbanner
      } else {
        smartbanner.publish()
      }
    }
  } catch (error) {
    console.error('Error initializing SmartBanner:', error.message)
  }
})
</script>

<template>
  <div id="smartbanner"></div>
</template>
