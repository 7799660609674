<script setup>
import { onMounted, onBeforeUnmount, ref, onBeforeMount, inject } from 'vue'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useRouter } from 'vue-router'
import ContactView from '@/views/contact/ContactView.vue'
import SmartBanner from '@/components/smartBanner/SmartBanner.vue'

const IS_MOBILE_APP = inject('IS_MOBILE_APP')
const router = useRouter()
const restoStore = useRestoGLobalStore()
const dynamicContent = restoStore?.restoInfo?.dynamic_site_content
const address = `${restoStore?.restoInfo?.address}`
const name = `${restoStore?.restoInfo?.name}`
const query = `${name} ${address}`
const mapSrc = `https://maps.google.com/maps?q=` + encodeURI(query) + `&output=embed`

const date = new Date()
const currentYear = date.getFullYear()
const isSticky = ref(false)
const showMobileHeader = ref(false)
const bgColor1 = ref('')

const handleScroll = () => {
  isSticky.value = window.scrollY > 80
}
const handleShowHeader = () => {
  showMobileHeader.value = !showMobileHeader.value
}
onBeforeMount(() => {
  if (dynamicContent?.style == 1) {
    import('@/layouts/dynamicSite/template4/css/style1.css')
    bgColor1.value = '#e67700'
  } else if (dynamicContent?.style == 2) {
    import('@/layouts/dynamicSite/template4/css/style2.css')
    bgColor1.value = '#FEA116'
  } else if (dynamicContent?.style == 3) {
    import('@/layouts/dynamicSite/template4/css/style3.css')
    bgColor1.value = '#8fa13e'
  } else {
    import('@/layouts/dynamicSite/template4/css/style4.css')
    bgColor1.value = '#fdb44b'
  }
})
onMounted(() => {
  window.addEventListener('scroll', handleScroll)
  const favicon = document.querySelector('link[rel="icon"]')
  if (favicon) {
    favicon.href = `${dynamicContent?.info?.logo}`
  } else {
    const newFavicon = document.createElement('link')
    newFavicon.rel = 'icon'
    newFavicon.type = 'image/x-icon'
    newFavicon.href = `${dynamicContent?.info?.logo}`
    document.head.appendChild(newFavicon)
  }
})
onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>
<template>
  <div class="temp-w4">
    <!-- SmartBanner -->
    <SmartBanner v-if="!IS_MOBILE_APP"></SmartBanner>
    <!-- ====== Header section start here ====== -->
    <header :class="{ 'sticky-header': isSticky }" class="text-white z-50 pb-5 md:pt-7 pt-6 px-3">
      <div class="container mx-auto flex items-center justify-between">
        <div class="flex items-center">
          <img :src="dynamicContent?.info?.logo" alt="Logo" class="h-12 w-12 mr-2" />
        </div>

        <!-- Navigation Links -->
        <nav class="hidden md:flex items-center space-x-10">
          <a href="#home" class="text-sm uppercase relative nav-link">Home</a>
          <a href="#aboutUs" class="text-sm uppercase relative nav-link">About Us</a>
          <router-link
            class="text-sm uppercase relative nav-link"
            :to="{ name: 'menu', params: { menu_slug: restoStore?.MENU_URL } }"
            >Online Order</router-link
          >
          <a href="#gallery" class="text-sm uppercase relative nav-link">Gallery</a>
          <a href="#contactUs" class="text-sm uppercase relative nav-link">Contact</a>
          <router-link
            v-if="dynamicContent?.info?.is_reservation_active"
            class="btn-custom px-8 py-3 text-base"
            :to="{ name: 'reservation', params: { resto_id: restoStore?.RESTO_ID } }"
            >Book Table</router-link
          >
        </nav>

        <!-- Mobile Menu Button -->
        <button class="md:hidden focus:outline-none btn-menu" @click="handleShowHeader()">
          <svg
            class="h-6 w-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 12h16m-7 6h7"
            ></path>
          </svg>
        </button>

        <!-- Mobile Menu -->
        <div
          :class="[showMobileHeader ? 'block' : 'hidden']"
          class="mobile-menu md:hidden bg-gray-600 absolute left-0 right-0 top-20 pb-4 animate__animated animate__zoom-in animate__delay-100"
        >
          <a href="#home" class="block hover:bg-gray-700 py-2 px-4">Home</a>
          <a href="#aboutUs" class="block hover:bg-gray-700 py-2 px-4">About Us</a>
          <router-link
            class="block hover:bg-gray-700 py-2 px-4"
            :to="{ name: 'menu', params: { menu_slug: restoStore?.MENU_URL } }"
            >Online Order</router-link
          >
          <a href="#gallery" class="block hover:bg-gray-700 py-2 px-4">Gallery</a>
          <a href="#contactUs" class="block hover:bg-gray-700 py-2 px-4">Contact</a>
          <router-link
            v-if="dynamicContent?.info?.is_reservation_active"
            class="btn-custom px-6 py-2 text-sm mt-4 mx-4"
            :to="{ name: 'reservation', params: { resto_id: restoStore?.RESTO_ID } }"
            >Book Table</router-link
          >
        </div>
      </div>
    </header>
    <!-- ====== Header section End ====== -->

    <!-- Offer text -->
    <div
      v-if="dynamicContent?.content?.rsc_resto_note_customer_message"
      class="container-fluid top-notice pt-2 px-3"
    >
      <div class="top-notice-bar">
        <div class="flex">
          <div class="flex-none w-1/6 md:w-1/12 lg:w-1/12 xl:w-1/12 text-white font-semibold">
            Notice!
          </div>
          <div class="flex-grow w-5/6 md:w-11/12 lg:w-11/12 xl:w-11/12 text-white">
            <marquee
              class="hover:cursor-pointer"
              onmouseover="this.stop();"
              onmouseout="this.start();"
            >
              {{ dynamicContent?.content?.rsc_resto_note_customer_message }}
            </marquee>
          </div>
        </div>
      </div>
    </div>

    <!-- ====== Images slider start here ===== -->
    <section
      id="hero"
      class="flex items-center banner"
      :style="{
        background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6)), url(${dynamicContent?.content?.rsc_slider_background_image_1_link}) center top`
      }"
    >
      <div
        class="container mx-auto text-center md:text-left animate__animated animate__zoom-in animate__delay-100"
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <div class="text-center text-white relative top-4">
          <h1 class="text-5xl lg:text-6xl font-semibold mb-3 text-white">
            {{ dynamicContent?.info?.name }}
          </h1>
          <div v-if="dynamicContent?.info?.active_website_offers">
            <p
              v-for="(offer, index) in dynamicContent?.info?.active_website_offers"
              :key="index"
              class="text-xl lg:text-2xl mb-2"
            >
              {{ offer }}
            </p>
          </div>
          <div class="space-x-4 md:mt-10 mt-8">
            <router-link
              class="btn-custom px-8 py-3 md:text-xl text-lg"
              :to="{ name: 'menu', params: { menu_slug: restoStore?.MENU_URL } }"
              >Order Now</router-link
            >
            <router-link
              v-if="dynamicContent?.info?.is_reservation_active"
              class="btn-custom px-8 py-3 md:text-xl text-lg"
              :to="{ name: 'reservation', params: { resto_id: restoStore?.RESTO_ID } }"
              >Book Table</router-link
            >
          </div>
          <a href="#aboutUs">
            <div class="mouse"></div>
          </a>
        </div>
      </div>
    </section>

    <!-- ====== Images slider End ===== -->

    <!-- ====== About section Start ===== -->
    <section
      v-if="dynamicContent?.content?.rsc_about_us_text_1"
      id="aboutUs"
      class="about-section md:py-20 py-16 px-4"
    >
      <div class="container mx-auto animate__animated animate__fade-up" data-aos="fade-up">
        <div class="md:grid md:grid-cols-2 gap-10">
          <div class="content">
            <div class="text-center mb-4">
              <img
                src="./images/crown-1.png"
                class="img-fluid inline-block"
                alt="Responsive image"
              />
              <h2 class="text-2xl lg:text-3xl uppercase my-2">About us</h2>
              <img
                src="./images/crown-2.png"
                class="img-fluid inline-block"
                alt="Responsive image"
              />
            </div>
            <p
              class="text-base leading-7 mb-4"
              v-html="dynamicContent?.content?.rsc_about_us_text_1"
            ></p>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_about_us_image_1_link"
            class="wow bounceInLeft general_left animated"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            <div class="about-img">
              <img :src="dynamicContent?.content?.rsc_about_us_image_1_link" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ====== About section End ===== -->

    <!-- ====== About section 2 ===== -->
    <section v-if="dynamicContent?.content?.rsc_about_us_text_2" class="about-section-2">
      <div class="container mx-auto animate__animated animate__fade-up" data-aos="fade-up">
        <div class="text-center mx-auto w-3/5">
          <div class="mb-8">
            <p v-html="dynamicContent?.content?.rsc_about_us_text_2"></p>
          </div>
          <!-- <div class="text-center mx-auto">
            <img src="https://placehold.co/650x400" alt="" class="inline-block" />
          </div> -->
        </div>
      </div>
    </section>

    <!-- ====== OUR Gallery section start ===== -->
    <section
      v-if="dynamicContent?.content?.rsc_gallery_image_1_link"
      id="gallery"
      class="gallery-section bg-white md:py-20 pb-16"
    >
      <div class="container mx-auto animate__animated animate__fade-up" data-aos="fade-up">
        <div class="text-center mb-4">
          <img src="./images/crown-1.png" class="img-fluid inline-block" alt="Responsive image" />
          <h2 class="text-2xl lg:text-3xl uppercase my-2">OUR GALLERY</h2>
          <img src="./images/crown-2.png" class="img-fluid inline-block" alt="Responsive image" />
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 content">
          <div
            v-if="dynamicContent?.content?.rsc_gallery_image_1_link"
            class="col-span-1 md:col-span-2 lg:col-span-2 px-2 py-2"
          >
            <div
              class="gallery-item"
              :style="{
                backgroundImage: `url(${dynamicContent?.content?.rsc_gallery_image_1_link})`
              }"
            ></div>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_gallery_image_2_link"
            class="col-span-1 md:col-span-1 lg:col-span-1 px-2 py-2"
          >
            <div
              class="gallery-item"
              :style="{
                backgroundImage: `url(${dynamicContent?.content?.rsc_gallery_image_2_link})`
              }"
            ></div>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_gallery_image_3_link"
            class="col-span-1 md:col-span-1 lg:col-span-1 px-2 py-2"
          >
            <div
              class="gallery-item"
              :style="{
                backgroundImage: `url(${dynamicContent?.content?.rsc_gallery_image_3_link})`
              }"
            ></div>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_gallery_image_4_link"
            class="col-span-1 md:col-span-1 lg:col-span-1 px-2 py-2"
          >
            <div
              class="gallery-item"
              :style="{
                backgroundImage: `url(${dynamicContent?.content?.rsc_gallery_image_4_link})`
              }"
            ></div>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_gallery_image_5_link"
            class="col-span-1 md:col-span-1 lg:col-span-1 px-2 py-2"
          >
            <div
              class="gallery-item"
              :style="{
                backgroundImage: `url(${dynamicContent?.content?.rsc_gallery_image_5_link})`
              }"
            ></div>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_gallery_image_6_link"
            class="col-span-1 md:col-span-1 lg:col-span-1 px-2 py-2"
          >
            <div
              class="gallery-item"
              :style="{
                backgroundImage: `url(${dynamicContent?.content?.rsc_gallery_image_6_link})`
              }"
            ></div>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_gallery_image_7_link"
            class="col-span-1 md:col-span-1 lg:col-span-1 px-2 py-2"
          >
            <div
              class="gallery-item"
              :style="{
                backgroundImage: `url(${dynamicContent?.content?.rsc_gallery_image_7_link})`
              }"
            ></div>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_gallery_image_8_link"
            class="col-span-1 md:col-span-1 lg:col-span-1 px-2 py-2"
          >
            <div
              class="gallery-item"
              :style="{
                backgroundImage: `url(${dynamicContent?.content?.rsc_gallery_image_8_link})`
              }"
            ></div>
          </div>
        </div>
      </div>
    </section>
    <!-- ====== OUR GALLERY section End ===== -->

    <!-- ====== OUR video section start ===== -->
    <section
      v-if="dynamicContent?.content?.rsc_vgallery_link_1"
      id="videos"
      class="container-fluid mx-auto md:py-20 py-12"
    >
      <div class="container mx-auto relative">
        <div class="text-center mb-4">
          <img src="./images/crown-1.png" class="img-fluid inline-block" alt="Responsive image" />
          <h2 class="text-xl lg:text-3xl uppercase my-2">Our Videos</h2>
          <img src="./images/crown-2.png" class="img-fluid inline-block" alt="Responsive image" />
        </div>
        <div class="flex justify-center flex-wrap">
          <div
            v-if="dynamicContent?.content?.rsc_vgallery_link_1"
            class="relative overflow-hidden w-2/4 px-2 mb-4 wow zoomIn general_grow animated"
          >
            <iframe width="100%" height="315" :src="dynamicContent?.content?.rsc_vgallery_link_1">
            </iframe>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_vgallery_link_2"
            class="relative overflow-hidden w-2/4 px-2 mb-4 wow zoomIn general_grow animated"
          >
            <iframe width="100%" height="315" :src="dynamicContent?.content?.rsc_vgallery_link_2">
            </iframe>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_vgallery_link_3"
            class="relative overflow-hidden w-2/4 px-2 mb-4 wow zoomIn general_grow animated"
          >
            <iframe width="100%" height="315" :src="dynamicContent?.content?.rsc_vgallery_link_3">
            </iframe>
          </div>
          <div
            v-if="dynamicContent?.content?.rsc_vgallery_link_4"
            class="relative overflow-hidden w-2/4 px-2 mb-4 wow zoomIn general_grow animated"
          >
            <iframe width="100%" height="315" :src="dynamicContent?.content?.rsc_vgallery_link_4">
            </iframe>
          </div>
        </div>
      </div>
    </section>
    <!-- ====== OUR video section End ===== -->

    <section class="">
      <div class="container mx-auto pb-16 px-3 animate__animated animate__fade-up address_area">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <!-- Opening Hours -->
          <div class="col-span-2 sm:col-span-1 open_hour">
            <img
              src="./images/open_hanger.png"
              class="img-fluid text-center inline-block"
              alt="Responsive image"
            />
            <div class="open_hour_wrap">
              <div class="open_hour_inner sm:px-6 px-4 sm:py-8 py-6">
                <h4>Opening Hours</h4>
                <div
                  class="flex"
                  v-for="openTime in dynamicContent?.info?.opening_times"
                  :key="openTime?.day_number"
                >
                  <p class="mb-1">{{ openTime.day_title }}:&nbsp;</p>
                  <p
                    class="mb-1 text-slate-300"
                    v-for="(time, index) in openTime.times"
                    :key="index"
                  >
                    {{ time }}
                  </p>
                </div>
                <h5 class="mt-5 text-center text-xl">Free Delivery</h5>
                <div>
                  <p>Available on orders over 10.00.<br />Within 2 miles of radius.</p>
                </div>
              </div>
            </div>
          </div>

          <!-- Get In Touch -->
          <div
            class="col-span-2 sm:col-span-1 lg:pl-16 md:pl-12 pl-6 md:mt-32 mt-8 address_area_inner"
          >
            <h2 class="add_text text-3xl mb-5 font-semibold w-full">Get In Touch <span>|</span></h2>

            <!-- Contact Info 1 -->
            <div class="contact_info_first flex items-center w-full pr-8 mb-4">
              <div class="contact_info_icon text-3xl"><i class="fa fa-phone"></i></div>
              <div class="media_body ml-3">
                <h2 class="text-xl mb-3">
                  <a :href="`tel:${dynamicContent?.info?.phone_number}`" class="">{{
                    dynamicContent?.info?.phone_number
                  }}</a>
                </h2>
              </div>
            </div>

            <!-- Contact Info 2 -->
            <div class="contact_info flex items-center w-full">
              <div class="contact_info_icon text-3xl"><i class="fa fa-home"></i></div>
              <div class="media_body ml-3">
                <h2 class="text-xl mb-2">{{ dynamicContent?.info?.address }}</h2>
                <!-- <p class="">United Kingdom</p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="online-main">
        <div class="online-news md:py-16 py-12 px-3">
          <div class="container online_order_inner mx-auto">
            <div class="flex justify-center text-center text-white">
              <div class="w-full lg:w-8/12 md:w-10/12 sm:w-11/12 xl:w-8/12">
                <div class="text-5xl mb-3">
                  <i class="fa fa-bullhorn"></i>
                </div>
                <h2 class="text-3xl md:text-3xl lg:text-4xl font-semibold mb-6">
                  New! Online Ordering
                </h2>
                <p v-if="dynamicContent?.content?.rsc_allergy_text_1" class="text-base">
                  {{ dynamicContent?.content?.rsc_allergy_text_1 }}<br />
                  {{ dynamicContent?.content?.rsc_allergy_text_2 }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </section>

    <!-- ====== CONTACT US section start ===== -->
    <section id="contactUs" class="container-fluid mx-auto md:py-16 py-12 contact-container">
      <div class="container mx-auto px-3">
        <div class="text-center mb-4">
          <img src="./images/crown-1.png" class="img-fluid inline-block" alt="Responsive image" />
          <h2 class="text-2xl lg:text-3xl uppercase my-2 text-white">CONTACT US</h2>
          <img src="./images/crown-2.png" class="img-fluid inline-block" alt="Responsive image" />
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div class="col-span-1">
            <ContactView :bgcolor="bgColor1"></ContactView>
          </div>
          <div class="col-span-1">
            <div class="map_container">
              <iframe
                width="100%"
                height="450"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                :src="mapSrc"
              >
              </iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ====== CONTACT US section End ===== -->

    <hr class="text-white" />
    <!-- ====== FOOTER Start ===== -->
    <footer id="footer" class="">
      <div class="text-white md:py-12 py-8 md:px-10 px-6">
        <div class="container mx-auto">
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <!-- Restaurantly Info -->
            <div class="col-span-1 md:col-span-2 lg:col-span-1">
              <!-- <h3 class="text-2xl font-bold">{{  }}</h3> -->
              <img
                :src="dynamicContent?.info?.logo"
                alt="Company logo"
                class="rounded-md shadow-md mb-4"
              />
              <div class="flex mt-4">
                <i class="fa fa-map-marker mr-2 mt-1"></i>
                <p class="">
                  {{ dynamicContent?.info?.address }}
                </p>
              </div>

              <div class="flex items-center">
                <i class="fa fa-phone mr-2"></i>
                <a :href="`tel:${dynamicContent?.info?.phone_number}`" class="">{{
                  dynamicContent?.info?.phone_number
                }}</a>
              </div>

              <!-- <div class="flex items-center">
                <i class="fa fa-envelope mr-2"></i>
                <a href="mailto:bookings@gmail.com" class="text-gray-300">bookings@gmail.com</a>
              </div> -->

              <div class="social-links mt-5">
                <a
                  v-if="dynamicContent.content?.rsc_social_media_link_facebook"
                  :href="dynamicContent.content?.rsc_social_media_link_facebook"
                  target="_blank"
                  class=""
                  ><i class="fa fa-facebook" aria-hidden="true"></i
                ></a>
                <a
                  v-if="dynamicContent.content?.rsc_social_media_link_instagram"
                  :href="dynamicContent.content?.rsc_social_media_link_instagram"
                  target="_blank"
                  class=""
                  ><i class="fa fa-instagram" aria-hidden="true"></i
                ></a>
                <a
                  v-if="dynamicContent.content?.rsc_social_media_link_twitter"
                  :href="dynamicContent.content?.rsc_social_media_link_twitter"
                  target="_blank"
                  class=""
                  ><i class="fa fa-twitter text-xl" aria-hidden="true"></i
                ></a>
              </div>
            </div>

            <!-- Useful Links -->
            <div class="col-span-1 md:col-span-1 lg:col-span-1">
              <h3 class="text-xl font-bold mb-4">Useful Links</h3>
              <ul>
                <li class="mb-2">
                  <i class="fa fa-chevron-right text-primary"></i>
                  <a href="#" class="ml-1">Terms of service</a>
                </li>
                <li class="mb-2">
                  <i class="fa fa-chevron-right text-primary"></i>
                  <a href="#" class="ml-1">Privacy policy</a>
                </li>
              </ul>
            </div>

            <!-- Our Newsletter -->
            <div
              v-if="dynamicContent?.content?.rsc_food_hygiene_rating > 3"
              class="col-span-1 md:col-span-2 lg:col-span-1"
            >
              <h3 class="text-xl font-bold mb-4">Food Hygiene Rating</h3>
              <div>
                <img
                  v-if="dynamicContent?.content?.rsc_food_hygiene_rating == 3"
                  src="./../common/images/food-rating-3.svg"
                  class="mb-4 inline-block w-80"
                />
                <img
                  v-else-if="dynamicContent?.content?.rsc_food_hygiene_rating == 4"
                  src="./../common/images/food-rating-4.svg"
                  class="mb-4 inline-block w-80"
                />
                <img
                  v-else
                  src="./../common/images/food-rating-5.svg"
                  class="mb-4 inline-block w-80"
                />
              </div>
              <p v-if="dynamicContent?.content?.rsc_allergy_text_1" class="text-base">
                {{ dynamicContent?.content?.rsc_allergy_text_1 }}<br />
                {{ dynamicContent?.content?.rsc_allergy_text_2 }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-gray-800 text-white py-4 px-3">
        <div class="container mx-auto">
          <div class="flex justify-between">
            <div class="txt-copyRight wow zoomIn general_grow animated">
              <p class="mb-0">&copy; {{ currentYear }} - All rights reserved</p>
            </div>
          </div>
        </div>
      </div>
    </footer>

    <a
      id="back-to-top"
      href="#"
      class="fixed bottom-4 right-4 rounded-full p-2 bg-white shadow-md hover:bg-gray-100"
    >
      <i class="fa fa-chevron-up text-gray-700" aria-hidden="true"></i>
    </a>
    <!-- ====== FOOTER End ===== -->
  </div>
</template>

<style scoped>
@import 'https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css';
@import '@/layouts/dynamicSite/template4/css/responsive.css';
</style>
