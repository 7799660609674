<script setup>
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useCartGLobalStore } from '@/stores/cartGlobalStore'
import { useAuthGlobalStore } from '@/stores/authGlobalStore'
import OrderType from '@/components/cart/orderType/OrderType.vue'
import MobileOrderType from '@/components/cart/orderType/MobileOrderType.vue'
import PaymentType from '@/components/cart/paymentType/PaymentType.vue'
import MobilePaymentType from '@/components/cart/paymentType/MobilePaymentType.vue'
import CartItems from '@/components/cart/cartItems/CartItems.vue'
import CartTotal from '@/components/cart/cartTotal/CartTotal.vue'
import { removeAllItemFn } from '@/services/api/restApiCalls'
import { useRouter } from 'vue-router'
import { ShoppingCartIcon } from '@heroicons/vue/20/solid'
import { inject } from 'vue'
const router = useRouter()
const restoStore = useRestoGLobalStore()
const cartStore = useCartGLobalStore()
const authStore = useAuthGlobalStore()
const IS_MOBILE_APP = inject('IS_MOBILE_APP')

const removeAllItems = async () => {
  const removeAllObj = {
    action: 'clear',
    resto_id: restoStore?.RESTO_ID
  }
  let removeAllFormData = new FormData()
  removeAllFormData.append('cart_data', JSON.stringify(removeAllObj))
  const cart_info = await removeAllItemFn(removeAllFormData)
  cartStore.CART_DATA = cart_info[restoStore?.RESTO_ID]
  cartStore.CAN_DO_CHECKOUT = cart_info?.can_do_checkout
}
const handleOrderCheckOut = () => {
  if (cartStore.CART_DATA?.order_type == 'delivery' && !cartStore?.CART_DATA?.pc_data?.is_valid) {
    cartStore.SHOW_POSTCODE_MODAL = true
    return
  }
  if (authStore.isLoggedIn) {
    router.push({ name: 'order_options' })
  } else {
    router.push({ name: 'login', query: { redirect: '/resto/order_options' } })
  }
}
</script>
<template>
  <div
    class="md:sticky md:top-20 md:rounded-lg border border-gray-300 shadow-sm bg-white z-30 md:z-auto hidden md:block"
  >
    <div class="relative cart-container px-3 pt-4 pb-2">
      <div class="flex items-baseline w-full justify-between">
        <h2
          class="text-lg lg:text-xl font-bold tracking-tight sm:text-1xl md:mb-2 mb-2"
        >
          Your order
        </h2>
        <!-- <template v-if="cartStore?.CART_DATA?.order_total_item_quantity != 0">
          <button
            type="button"
            class="rounded-full border border-transparent text-white shadow-sm focus:outline-none focus:ring-2"
            @click="removeAllItems()"
          >
            <TrashIcon class="h-5 w-5 text-gray-500 mx-auto" aria-hidden="true" />
          </button>
        </template> -->
      </div>
      <!-- minimum order on delivery/collection -->
      <div
        v-if="cartStore?.CART_DATA?.promo_min_order_text"
        class="bg-red-500 text-white rounded-sm text-sm font-medium px-2 py-1 whitespace-nowrap mb-2"
      >
        {{ cartStore?.CART_DATA?.promo_min_order_text }}
      </div>
      <div class="md:block hidden">
        <!-- Order type -->
        <OrderType></OrderType>
        <!-- Payment type -->
        <PaymentType></PaymentType>
      </div>
      <!-- Cart items -->
      <hr class="w-full mt-2 mb-1" />
      <div class="cart-items">
        <CartItems></CartItems>
      </div>
      <!-- Cart total -->
      <hr class="w-full my-1" />
      <CartTotal></CartTotal>
    </div>
    <div
      class="md:sticky bottom-0 w-full z-10 mx-auto bg-white px-3 py-2 border-t border-gray-200 shadow-sm"
    >
      <button
        type="submit"
        :disabled="cartStore?.CAN_DO_CHECKOUT == 0"
        class="w-full flex justify-between rounded-md border border-transparent bg-violet-700 py-2 px-4 text-base font-medium text-white shadow-sm hover:bg-e-violet focus:outline-none focus:ring-offset-gray-50 disabled:bg-gray-300 disabled:text-gray-400"
        @click="handleOrderCheckOut()"
      >
        <span class="flex items-center"
          ><ShoppingCartIcon class="sm:h-5 h-4 sm:w-5 w-4 mr-1" aria-hidden="true" />{{
            cartStore?.CART_DATA?.order_total_item_quantity || 0
          }}</span
        >
        <span>Go To Checkout</span>
        <span>£{{ cartStore?.CART_DATA?.order_total || 0 }}</span>
      </button>
    </div>
  </div>
  <div class="md:hidden block">
    <div
      class="z-10 left-0 right-0 mx-auto border-t sm:px-5 px-3 py-2 bg-white fixed"
      :class="[IS_MOBILE_APP ? 'bottom-5' : 'bottom-0']"
    >
      <div class="w-full">
        <MobileOrderType></MobileOrderType>
        <MobilePaymentType></MobilePaymentType>
      </div>
      <div class="flex w-full">
        <button
          type="submit"
          class="w-full flex justify-between rounded-md border border-transparent bg-violet-700 py-1 px-4 text-base font-medium text-white shadow-sm hover:bg-e-violet focus:outline-none focus:ring-offset-gray-50 disabled:bg-gray-300 disabled:text-gray-400"
          @click="
            $router.push({
              name: 'view_mobile_cart'
            })
          "
        >
          <span class="flex items-center"
            ><ShoppingCartIcon class="sm:h-5 h-4 sm:w-5 w-4 mr-1" aria-hidden="true" />{{
              cartStore?.CART_DATA?.order_total_item_quantity || 0
            }}</span
          >
          <span>View basket</span>
          <span>£{{ cartStore?.CART_DATA?.order_total || 0 }}</span>
        </button>
      </div>
    </div>
  </div>
</template>
<style scoped>
@media only screen and (max-width: 768px) {
  .show-cart-mobile {
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    height: calc(100vh - 130px);
    top: -10px;
    width: 100%;
    overflow: auto;
  }
}
.cart-container {
  height: calc(100vh - 190px);
  overflow: auto;
}
</style>
