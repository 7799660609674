<script>
import AppLayout from '@/layouts/AppLayout.vue'
import SettingMenuLink from '@/views/account/setting/common/SettingMenuLink.vue'
import { ref, computed, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, email } from '@vuelidate/validators'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { updateEmailFn, isCustomerEmailAvailableFn } from '@/services/api/restApiCalls'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/20/solid'
export default {
  components: {
    AppLayout,
    CheckCircleIcon,
    XCircleIcon,
    SettingMenuLink
  },
  setup() {
    const restoStore = useRestoGLobalStore()
    const router = useRouter()
    // const emailId = ref('')
    // const newEmail = ref('')
    const emailInvalid = ref(false)
    const emailValid = ref(false)
    const submitted = ref(false)
    const updateEmailData = ref({})
    const userInitialValues = {
      emailId: '',
      newEmail: ''
    }
    let user = reactive({ ...userInitialValues })

    const checkIfEmailAvailable = async (value) => {
      // TOODO: add api call to check if email is available here
      let formData = new FormData()
      formData.append('email', value)
      const response = await isCustomerEmailAvailableFn(formData)
      const isEmailAvailable = response.is_email_available || false
      return isEmailAvailable
    }
    const validationRules = computed(() => {
      let rules = {
        emailId: { required, email },
        newEmail: {
          required,
          email,
          $lazy: true,
          isUnique: helpers.withAsync(
            helpers.withMessage('Email not available', checkIfEmailAvailable)
          )
        }
      }
      return rules
    })
    const v$ = useVuelidate(validationRules, user)
    const handleUpdateEmail = async () => {
      if (submitted.value) return
      submitted.value = true
      v$.value.$validate()
      if (v$.value.$invalid) {
        return
      }
      let updateEmailFormData = new FormData()
      updateEmailFormData.append('email', newEmail.value)
      const update_email = await updateEmailFn(updateEmailFormData)
      updateEmailData.value = update_email
      if (!update_email?.error_code) {
        emailValid.value = true
        emailInvalid.value = false
      } else {
        emailInvalid.value = true
      }
      submitted.value = false
    }
    return {
      // emailId,
      // newEmail,
      user,
      emailInvalid,
      emailValid,
      submitted,
      v$,
      router,
      restoStore,
      updateEmailData,
      handleUpdateEmail
    }
  }
}
</script>
<template>
  <AppLayout>
    <!-- <h1>Update customer email VIEW</h1> -->
    <SettingMenuLink></SettingMenuLink>
    <div
      class="flex flex-col items-center justify-center sm:px-6 px-3 sm:py-8 py-4 mx-auto pt:mt-0"
    >
      <div class="w-full max-w-xl p-6 sm:p-8 bg-white rounded-lg shadow">
        <h1 class="text-2xl font-bold text-gray-900 text-center mb-8">Change Email</h1>

        <div class="">
          <form novalidate @submit.prevent="handleUpdateEmail">
            <div class="space-y-4">
              <div v-if="updateEmailData?.status == 400" class="rounded-md bg-red-50 p-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <XCircleIcon class="h-6 w-6 text-red-400" aria-hidden="true" />
                  </div>
                  <div class="ml-3">
                    <p class="text-md font-medium text-red-800">
                      {{ updateEmailData?.status_msg }}
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="updateEmailData?.status == 200" class="rounded-md bg-green-50 p-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <CheckCircleIcon class="h-6 w-6 text-green-400" aria-hidden="true" />
                  </div>
                  <div class="ml-3">
                    <p class="text-md font-medium text-green-800">Email Updated succesfully</p>
                  </div>
                </div>
              </div>
              <div class="">
                <label for="email" class="block text-sm font-medium text-gray-700"
                  >Current Email address</label
                >
                <div class="mt-1">
                  <input
                    id="email"
                    v-model="user.emailId"
                    name="email"
                    placeholder="Enter current email"
                    type="email"
                    autocomplete="email"
                    :class="{ 'border-red-600': submitted && v$.emailId.$error }"
                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                  />
                </div>
                <div v-for="(error, index) of v$.emailId.$errors" :key="index" class="input-errors">
                  <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
                </div>
              </div>
              <div class="">
                <label for="email" class="block text-sm font-medium text-gray-700"
                  >New Email address</label
                >
                <div class="mt-1">
                  <input
                    id="newEmail"
                    v-model="user.newEmail"
                    name="newEmail"
                    placeholder="Enter new email"
                    type="email"
                    autocomplete="email"
                    :class="{ 'border-red-600': submitted && v$.newEmail.$error }"
                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                  />
                </div>
                <div
                  v-for="(error, index) of v$.newEmail.$errors"
                  :key="index"
                  class="input-errors"
                >
                  <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
                </div>
              </div>

              <button
                type="submit"
                class="w-full justify-center rounded-md border border-transparent bg-violet-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-violet-800 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2"
              >
                Save
              </button>
              <button
                type="button"
                class="w-full rounded-md border border-transparent bg-gray-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-offset-gray-50"
                @click="
                  router.push({
                    name: 'menu',
                    params: { menu_slug: restoStore.MENU_URL }
                  })
                "
              >
                Back
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </AppLayout>
</template>
