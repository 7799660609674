<script>
import AppLayout from '@/layouts/AppLayout.vue'
import { ref, computed } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { CheckCircleIcon } from '@heroicons/vue/20/solid'
import { playStoreDeleteDataFn } from '@/services/api/restApiCalls'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useRouter } from 'vue-router'

export default {
  components: {
    CheckCircleIcon,
    AppLayout
  },
  setup() {
    // const authStore = useAuthGlobalStore()
    const restoStore = useRestoGLobalStore()
    const router = useRouter()

    const name = ref('')
    const emailId = ref('')
    const message = ref('')
    const submitted = ref(false)
    const deleteDataInfo = ref({})

    const validationRules = computed(() => {
      let rules = {
        name: { required },
        emailId: {
          required,
          email
        },
        message: { required }
      }
      return rules
    })
    const v$ = useVuelidate(validationRules, {
      name,
      emailId,
      message
    })
    const handlePlayStoreDeleteData = async () => {
      submitted.value = true
      v$.value.$validate()
      if (v$.value.$invalid) {
        return
      }
      const currentDate = new Date().toISOString().split('T')[0] //'YYYY-MM-DD' format
      const playStoreDeleteDataCaptcha = `beluga-playstore-deletedata-request-${currentDate}`
      const base64EncodedValue = btoa(playStoreDeleteDataCaptcha) //Encoded signupCaptcha
      const playStoreDeleteFormData = new FormData()
      playStoreDeleteFormData.append('name', name.value)
      playStoreDeleteFormData.append('email', emailId.value)
      playStoreDeleteFormData.append('message', message.value)
      playStoreDeleteFormData.append('beluga_captcha', base64EncodedValue)
      const delete_data_info = await playStoreDeleteDataFn(
        restoStore?.RESTO_ID,
        playStoreDeleteFormData
      )
      deleteDataInfo.value = delete_data_info
    }

    return {
      name,
      emailId,
      message,
      submitted,
      deleteDataInfo,
      v$,
      router,
      restoStore,
      handlePlayStoreDeleteData
    }
  }
}
</script>
<template>
  <AppLayout>
    <div class="flex flex-col items-center justify-center sm:px-6 px-3 sm:py-8 py-4 mx-auto">
      <div class="w-full max-w-xl p-6 sm:p-8 bg-white rounded-lg shadow">
        <h2 class="text-3xl font-bold text-gray-900 text-center mb-1">Apricot IT LTD</h2>
        <p class="text-sm text-center mb-4">Developer Name: OnlyGulp, Inc.</p>
        <h2 class="text-2xl font-bold text-gray-900 text-center mb-4">
          {{ restoStore?.restoInfo?.name }} Mobile App
        </h2>
        <div v-if="deleteDataInfo?.status == 200" class="text-center">
          <CheckCircleIcon class="h-14 w-14 text-green-500 mx-auto" aria-hidden="true" />
          <h3 class="text-xl font-semibold text-gray-700 my-1">
            We are received your request to delete data. Thank you!
          </h3>
        </div>
        <div v-else class="">
          <h2 class="text-lg font-normal text-gray-900 text-center mb-2">
            Please fill this form to submit request to delete your data
          </h2>
          <div class="">
            <form class="space-y-4" @submit.prevent="handlePlayStoreDeleteData">
              <div class="md:grid md:grid-cols-12 md:gap-x-6">
                <div class="md:col-span-12">
                  <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
                  <div class="mt-1 text-gray-800">
                    <input
                      id="name"
                      v-model="name"
                      name="name"
                      type="text"
                      autocomplete="name"
                      placeholder="Enter name"
                      :class="{ 'border-red-600': submitted && v$.name.$error }"
                      class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                    />
                  </div>
                  <div v-for="(error, index) of v$.name.$errors" :key="index" class="input-errors">
                    <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div class="md:grid md:grid-cols-12 md:gap-x-6">
                <div class="md:col-span-12">
                  <label for="email" class="block text-sm font-medium text-gray-700"
                    >Email address</label
                  >
                  <div class="mt-1 text-gray-800">
                    <input
                      id="email"
                      v-model="emailId"
                      name="email"
                      type="email"
                      autocomplete="email"
                      placeholder="Enter email"
                      :class="{ 'border-red-600': submitted && v$.emailId.$error }"
                      class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                    />
                  </div>
                  <div
                    v-for="(error, index) of v$.emailId.$errors"
                    :key="index"
                    class="input-errors"
                  >
                    <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div class="md:grid md:grid-cols-12 md:gap-x-6">
                <div class="md:col-span-12">
                  <label for="message" class="block text-sm font-medium text-gray-700"
                    >Message</label
                  >
                  <div class="mt-1 text-gray-800">
                    <textarea
                      id="message"
                      v-model="message"
                      name="message"
                      autocomplete="message"
                      placeholder="Write something here..."
                      :class="{ 'border-red-600': submitted && v$.message.$error }"
                      class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                    ></textarea>
                  </div>
                  <div
                    v-for="(error, index) of v$.message.$errors"
                    :key="index"
                    class="input-errors"
                  >
                    <div class="text-sm font-medium text-red-600">{{ error.$message }}</div>
                  </div>
                </div>
              </div>

              <div class="flex justify-center space-x-2">
                <button
                  type="button"
                  class="w-2/4 rounded-md border border-transparent bg-gray-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-offset-gray-50"
                  @click="$router.push({ name: 'restoHome' })"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  class="w-2/4 rounded-md border border-transparent py-2 px-4 text-sm font-medium text-white shadow-sm bg-violet-800 hover:bg-violet-700 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>
