<script>
import AppLayout from '@/layouts/AppLayout.vue'
import { ref, onBeforeMount } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { required, sameAs, minLength } from '@vuelidate/validators'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/20/solid'
import { verifyTokenFn, resetPasswordFn } from '@/services/api/restApiCalls'

export default {
  components: {
    AppLayout,
    CheckCircleIcon,
    XCircleIcon
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const newPassword = ref('')
    const confirmPassword = ref('')
    const submitted = ref(false)
    const resetPassToken = ref(route.params.token)
    const invalidToken = ref(false)
    const passwordReset = ref(false)

    const validationRules = {
      newPassword: {
        required,
        minLength: minLength(7)
      },
      confirmPassword: {
        required,
        sameAs: sameAs(newPassword)
      }
    }

    const v$ = useVuelidate(validationRules, {
      newPassword,
      confirmPassword
    })

    const handleVerifyToken = async () => {
      let tokenFormData = new FormData()
      tokenFormData.append('token', resetPassToken.value)
      const verify_token_res = await verifyTokenFn(tokenFormData)
      if (verify_token_res?.status == 200) {
        invalidToken.value = false
      } else {
        invalidToken.value = true
      }
    }

    const handleResetPassword = async () => {
      submitted.value = true
      v$.value.$validate()
      if (v$.value.$invalid) {
        return
      }
      let resetPassFormData = new FormData()
      resetPassFormData.append('password', newPassword.value)
      resetPassFormData.append('confirm_password', confirmPassword.value)
      resetPassFormData.append('token', resetPassToken.value)
      const reset_password_res = await resetPasswordFn(resetPassFormData)
      if (reset_password_res?.status == 200) {
        passwordReset.value = true
      } else {
        passwordReset.value = false
      }
    }
    onBeforeMount(() => {
      handleVerifyToken()
    })
    return {
      router,
      v$,
      newPassword,
      confirmPassword,
      submitted,
      invalidToken,
      passwordReset,
      handleResetPassword
    }
  }
}
</script>
<template>
  <AppLayout>
    <div
      class="flex flex-col items-center justify-center sm:px-6 px-3 sm:py-8 py-4 mx-auto pt:mt-0"
    >
      <div class="w-full max-w-xl p-6 space-y-4 sm:p-8 bg-white rounded-lg shadow dark:bg-gray-800">
        <div v-if="passwordReset" class="rounded-md bg-green-50 p-4">
          <div class="flex">
            <div class="flex-shrink-0">
              <CheckCircleIcon class="h-6 w-6 text-green-400" aria-hidden="true" />
            </div>
            <div class="ml-3">
              <p class="text-md font-medium text-green-800">
                Your password has been changed successfully
              </p>
            </div>
          </div>
        </div>

        <div v-if="invalidToken">
          <div class="rounded-md bg-red-50 p-4 mb-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <XCircleIcon class="h-6 w-6 text-red-400" aria-hidden="true" />
              </div>
              <div class="ml-3">
                <p class="text-md font-medium text-red-800">
                  This password reset link has expired or already been used
                </p>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!invalidToken && !passwordReset">
          <h2 class="text-center text-2xl font-bold tracking-tight text-gray-900">
            Reset your password
          </h2>
          <div class="mt-8 space-y-4">
            <div>
              <div class="mb-4">
                <label for="email" class="block text-sm font-medium text-gray-700"
                  >New Password</label
                >
                <div class="mt-1">
                  <input
                    id="newPassword"
                    name="password"
                    type="Password"
                    placeholder="Enter new password"
                    :class="{ 'border-red-600': submitted && v$.newPassword.$error }"
                    autofocus
                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                    v-model="newPassword"
                  />
                </div>
                <div
                  v-for="(error, index) of v$.newPassword.$errors"
                  :key="index"
                  class="input-errors"
                >
                  <div class="text-sm font-medium text-red-600">
                    {{ error.$message }}
                  </div>
                </div>
              </div>
              <div class="mb-4">
                <label for="email" class="block text-sm font-medium text-gray-700"
                  >Confirm New Password</label
                >
                <div class="mt-1">
                  <input
                    id="confirmPassword"
                    name="password"
                    type="Password"
                    placeholder="Confirm new password"
                    :class="{ 'border-red-600': submitted && v$.confirmPassword.$error }"
                    autofocus
                    class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                    v-model="confirmPassword"
                  />
                </div>
                <div
                  v-for="(error, index) of v$.confirmPassword.$errors"
                  :key="index"
                  class="input-errors"
                >
                  <div class="text-sm font-medium text-red-600">
                    {{ error.$message }}
                  </div>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  class="w-full justify-center rounded-md border border-transparent bg-violet-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-violet-800 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2"
                  @click="handleResetPassword()"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <button
          type="submit"
          class="w-full rounded-md border border-transparent bg-gray-400 py-2 px-4 mt-4 text-sm font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-offset-gray-50"
          @click="$router.push({ name: 'login' })"
        >
          Back to Login
        </button>
      </div>
    </div>
  </AppLayout>
</template>
