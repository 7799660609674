<script setup>
import { useRouter } from 'vue-router'
import { useAppNavigation } from '@/composables/AppNavigation'
import { inject } from 'vue'
const { getUserSettingsNavLinks } = useAppNavigation()
const router = useRouter()
const IS_MOBILE_APP = inject('IS_MOBILE_APP')
let navLinks = getUserSettingsNavLinks()
if (IS_MOBILE_APP) {
  navLinks.push({
    name: 'Delete Account',
    route_name: 'playStoreDeleteData'
  })
}
</script>

<template>
  <div>
    <!-- <div class="sm:hidden">
          <label for="tabs" class="sr-only">Select a tab</label>
          <select
            id="tabs"
            name="tabs"
            class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          >
            <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">
              {{ tab.name }}
            </option>
          </select>
        </div> -->
    <div class="mx-auto px-4 sm:px-6 lg:px-8 bg-white">
      <div class="border-b border-gray-200">
        <nav class="flex justify-center sm:space-x-8 space-x-4" aria-label="Tabs">
          <RouterLink
            v-for="navLink in navLinks"
            :key="navLink.name"
            :to="{ name: navLink.route_name, params: navLink.params, query: navLink.query }"
            :class="[
              router.currentRoute.value.name === navLink.route_name
                ? '!border-indigo-500 text-gray-900'
                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
              'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium'
            ]"
            :aria-current="
              router.currentRoute.value.name === navLink.route_name ? 'page' : undefined
            "
            >{{ navLink.name }}</RouterLink
          >
        </nav>
      </div>
    </div>
  </div>
</template>
