<script>
import AppLayout from '@/layouts/AppLayout.vue'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/20/solid'
import { forgotPasswordFn } from '@/services/api/restApiCalls'

export default {
  components: {
    AppLayout,
    CheckCircleIcon,
    XCircleIcon
  },
  setup() {
    const router = useRouter()
    const userEmail = ref('')
    const submitted = ref(false)
    const emailValid = ref(false)
    const emailInvalid = ref(false)

    const validationRules = {
      userEmail: {
        required,
        email
      }
    }

    const v$ = useVuelidate(validationRules, {
      userEmail
    })

    const handleForgotPassword = async () => {
      submitted.value = true
      v$.value.$validate()
      if (v$.value.$invalid) {
        return
      }
      emailValid.value = false
      emailInvalid.value = false
      let forgotPassFormData = new FormData()
      forgotPassFormData.append('email', userEmail.value)
      // emailValid.value = false
      // emailInvalid.value = false
      const forgot_password_res = await forgotPasswordFn(forgotPassFormData)
      if (forgot_password_res?.status == 200) {
        emailValid.value = true
      } else {
        emailInvalid.value = true
        // this.$refs.email.focus()
      }
    }
    return {
      router,
      v$,
      userEmail,
      submitted,
      emailValid,
      emailInvalid,
      handleForgotPassword
    }
  }
}
</script>
<template>
  <AppLayout>
    <!-- <h1>Customer forgot password VIEW</h1> -->
    <div
      class="flex flex-col items-center justify-center sm:px-6 px-3 sm:py-8 py-4 mx-auto pt:mt-0"
    >
      <div class="w-full max-w-xl p-6 space-y-8 sm:p-8 bg-white rounded-lg shadow">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 class="text-center text-2xl font-bold tracking-tight text-gray-900">
            Forgotten your password
          </h2>
        </div>

        <div class="mt-8 space-y-4">
          <div v-if="emailValid" class="rounded-md bg-green-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <CheckCircleIcon class="h-6 w-6 text-green-400" aria-hidden="true" />
              </div>
              <div class="ml-3">
                <p class="text-md font-medium text-green-800">
                  Forgot password link sent to your email
                </p>
              </div>
            </div>
          </div>
          <div v-if="emailInvalid" class="rounded-md bg-red-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <XCircleIcon class="h-6 w-6 text-red-400" aria-hidden="true" />
              </div>
              <div class="ml-3">
                <p class="text-md font-medium text-red-800">Please enter valid email</p>
              </div>
            </div>
          </div>
          <div v-if="!emailValid">
            <div class="mb-4">
              <label for="email" class="block text-sm font-medium text-gray-700"
                >Email address</label
              >
              <div class="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  placeholder="Enter email address"
                  :class="{ 'border-red-600': submitted && v$.userEmail.$error }"
                  autofocus
                  class="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 text-sm"
                  v-model="userEmail"
                />
              </div>
              <div v-for="(error, index) of v$.userEmail.$errors" :key="index" class="input-errors">
                <div class="text-sm font-medium text-red-600">
                  {{ error.$message }}
                </div>
              </div>
            </div>

            <div>
              <button
                type="submit"
                class="w-full justify-center rounded-md border border-transparent bg-violet-700 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-violet-800 focus:outline-none focus:ring-2 focus:ring-violet-500 focus:ring-offset-2"
                @click="handleForgotPassword()"
              >
                Submit
              </button>
            </div>
          </div>
          <div>
            <button
              type="submit"
              class="w-full rounded-md border border-transparent bg-gray-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-offset-gray-50"
              @click="$router.push({ name: 'login' })"
            >
              Back to Login
            </button>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>
