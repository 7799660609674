<script setup>
import { onMounted, inject } from 'vue'
import AppHeader from '@/components/common/AppHeader.vue'
import AppFooter from '@/components/common/AppFooter.vue'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import SmartBanner from '@/components/smartBanner/SmartBanner.vue'

const IS_MOBILE_APP = inject('IS_MOBILE_APP')
const restoStore = useRestoGLobalStore()

onMounted(() => {
  const favicon = document.querySelector('link[rel="icon"]')
  if (favicon) {
    favicon.href = `${restoStore?.restoInfo?.dynamic_site_content?.info?.logo}`
  } else {
    const newFavicon = document.createElement('link')
    newFavicon.rel = 'icon'
    newFavicon.type = 'image/x-icon'
    newFavicon.href = `${restoStore?.restoInfo?.dynamic_site_content?.info?.logo}`
    document.head.appendChild(newFavicon)
  }
})
</script>
<template>
  <slot name="header">
    <SmartBanner v-if="!IS_MOBILE_APP"></SmartBanner>
    <AppHeader />
  </slot>

  <main class="px-safe">
    <div class="mx-auto bg-gray-100 main-wrapper">
      <slot name="default"></slot>
    </div>
  </main>

  <slot name="footer">
    <AppFooter />
  </slot>
</template>
<style scoped>
.main-wrapper {
  min-height: calc(100vh - 111px);
}
</style>
```
