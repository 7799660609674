<script setup>
import AppLayout from '@/layouts/AppLayout.vue'
import { onMounted } from 'vue'
import { useCartGLobalStore } from '@/stores/cartGlobalStore'
import { useRestoGLobalStore } from '@/stores/restoGlobalStore'
import { useAuthGlobalStore } from '@/stores/authGlobalStore'
import OrderType from '@/components/cart/orderType/OrderType.vue'
import PaymentType from '@/components/cart/paymentType/PaymentType.vue'
import CartItems from '@/components/cart/cartItems/CartItems.vue'
import CartTotal from '@/components/cart/cartTotal/CartTotal.vue'
import { updateMobileCartFn } from '@/services/api/restApiCalls'
import { useRouter } from 'vue-router'
import { ShoppingCartIcon } from '@heroicons/vue/20/solid'
import { inject } from 'vue'
const router = useRouter()
const cartStore = useCartGLobalStore()
const restoStore = useRestoGLobalStore()
const authStore = useAuthGlobalStore()
const IS_MOBILE_APP = inject('IS_MOBILE_APP')

const handleMobileCart = async () => {
  let mobileCartFormData = new FormData()
  mobileCartFormData.append('resto_id', restoStore?.RESTO_ID)
  mobileCartFormData.append('is_mobile', 1)
  const mobile_cart = await updateMobileCartFn(mobileCartFormData)
}

onMounted(() => {
  cartStore.fetchCartInfo()
  if (!cartStore?.CART_DATA?.is_mobile) {
    handleMobileCart()
  }
})
const handleOrderCheckOut = () => {
  if (authStore.isLoggedIn) {
    router.push({ name: 'order_options' })
  } else {
    router.push({ name: 'login', query: { redirect: '/resto/order_options' } })
  }
}
</script>
<template>
  <AppLayout>
    <div class="border border-gray-300 shadow-sm px-3 py-4 bg-white z-30 md:z-auto">
      <div class="relative">
        <div class="flex items-baseline w-full justify-between">
          <h2 class="text-lg lg:text-xl font-bold tracking-tight sm:text-1xl md:mb-2 mb-2">
            Your order
          </h2>
        </div>
        <!-- minimum order on delivery/collection -->
        <div
          v-if="cartStore?.CART_DATA?.promo_min_order_text"
          class="bg-red-500 text-white rounded-sm text-sm font-medium px-2 py-1 whitespace-nowrap mb-2"
        >
          {{ cartStore?.CART_DATA?.promo_min_order_text }}
        </div>
        <!-- Order type -->
        <OrderType></OrderType>
        <!-- Payment type -->
        <PaymentType></PaymentType>
        <!-- Cart items -->
        <hr class="w-full mt-3 mb-2" />
        <CartItems></CartItems>
        <!-- Cart total -->
        <hr class="w-full my-3" />
        <CartTotal></CartTotal>
        <div
          class="sticky w-full z-10 mx-auto bg-white border-t border-gray-200 shadow-sm"
          :class="[IS_MOBILE_APP ? 'bottom-5' : 'bottom-0']"
        >
          <button
            type="submit"
            :disabled="
              cartStore?.CAN_DO_CHECKOUT == 0 ||
              (cartStore?.CART_DATA?.order_type == 'delivery' &&
                !cartStore?.CART_DATA?.pc_data?.is_valid)
            "
            class="w-full flex justify-between rounded-md border border-transparent bg-violet-700 py-2 px-4 text-base font-medium text-white shadow-sm hover:bg-e-violet focus:outline-none focus:ring-offset-gray-50 disabled:bg-gray-300 disabled:text-gray-400"
            @click="handleOrderCheckOut()"
          >
            <span class="flex items-center"
              ><ShoppingCartIcon class="sm:h-5 h-4 sm:w-5 w-4 mr-1" aria-hidden="true" />{{
                cartStore?.CART_DATA?.order_total_item_quantity || 0
              }}</span
            >
            <span>Go To Checkout</span>
            <span>£{{ cartStore?.CART_DATA?.order_total || 0 }}</span>
          </button>
          <button
            type="submit"
            class="w-full rounded-md border border-transparent bg-gray-400 py-2 px-4 mt-3 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-offset-gray-50"
            @click="
              $router.push({
                name: 'menu',
                params: { menu_slug: restoStore?.MENU_URL }
              })
            "
          >
            Add more items
          </button>
        </div>
      </div>
    </div>
  </AppLayout>
</template>
